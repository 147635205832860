class RegulatoryFeature {
    
    constructor(feature) {
      this.feature = feature;
      this.latitude = feature.properties.LAT2;
      this.longitude = feature.properties.LON2;
    }

    validRegulatoryFeature() {
      return (this.feature && this.feature.properties && this.feature.properties.DISTRICT) ? true : false;
    }

    formattedDistrictName() {
      return this.feature.properties.DISTRICT +" District ("+this.feature.properties.DIST_ABBR+")";
    }

    formattedDistrictNameAndAddress() {
      return this.feature.properties.DISTRICT +" District ("+this.feature.properties.DIST_ABBR+") | Address " + this.feature.properties.DISTRICT_A +  " |  Phone " + this.feature.properties.DISTRICT_N +  " |  URL " + this.feature.properties.WEB_ADDR;
    }

    url() {
      return this.feature.properties.WEB_ADDR
    }

}

export default RegulatoryFeature;