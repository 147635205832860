const basemaps = [
    {
        default: false,
        name: "ESRI USA Topo Maps",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/USA_Topo_Maps/MapServer/tile/{z}/{y}/{x}",
        attribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
        accessToken: null,
    },
    {
        default: true,
        name: "OpenStreetMap",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: "&copy; <a rel=external; href='http://osm.org/copyright' target='_blank'>OpenStreetMap</a> contributors",
        accessToken: null,
    },
    {
        default: false,
        name: "Mapbox Streets",
        url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token={accessToken}",
        attribution: "Imagery from <a rel=external; href='https://mapbox.com/about/maps/' target='_blank'>MapBox</a> &mdash; Map data &copy; <a href='https://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a>",
        accessToken: `${process.env.REACT_APP_MAPBOX_TOKEN}`
    }
]

export default basemaps;