import React from 'react';
import Map from './components/Map';
import './App.css';
// import headerImg from './images/usace-black-small2.png';
// import 'leaflet/dist/leaflet.css';  //fix this 

function App() {
  const headerName = "USACE Regulatory Boundaries";

  return (
    <div>       
      <h1>
        <div id="leftbox">
          <div className='headerImage'></div>
          {headerName}
        </div>
        <div id="middlebox">
          <div className=''>
          <table>
            <tbody>
            <tr><td style={{verticalAlign: 'top'}}>
                  <svg style={{ 
                      width: '24px',
                      height: '24px'
                    }} viewBox="0 0 24 24">
                    <path fill="#EEE" d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
                  </svg>
                </td>
                <td>
                  <div className="instruction">Please either enter an address or click on the map to display the contact information for your local Corps Regulatory office.  The name, address, phone number, and website address will be provided.</div>
                </td>
            </tr>
            </tbody>
          </table>  
          </div>
        </div>
        
      </h1>
      <Map/>
    </div>
  );
}

export default App;