import { useEffect } from 'react';
import { GeoSearchControl } from 'leaflet-geosearch';
import { useMap } from 'react-leaflet';
import LocationIcon from './LocationIcon';
import OfficeIcon from './OfficeIcon';
import regulatoryBoundaries from "../assets/regulatory-boundaries";
import RegulatoryFeature from '../assets/regulatory-feature';
import L from 'leaflet';
import point from "turf-point";
import inside from "turf-inside";
import { latLngBounds } from 'leaflet';


const GeoSearch = ( props ) => {
  const map = useMap();

  useEffect(() => {
    const features = regulatoryBoundaries.features;
    // Uncommit below to get an office list.  
    // features.forEach(feature => {
    //   const regulatoryFeature = new RegulatoryFeature(feature);
    //   console.log(regulatoryFeature.formattedDistrictNameAndAddress())
    // });

    const icon = LocationIcon;
    
    const searchControl = new GeoSearchControl({
      provider: props.provider,
      style: 'bar',
      marker: {
        icon
      },
      ...props,
    });

    function searchEventHandler(address) {
      let markerBounds = latLngBounds([]);
      let officeIcon = OfficeIcon;
      markerBounds.extend([address.location.y, address.location.x]);
    
      const pt = point([address.location.x, address.location.y]);

      features.forEach(feature => {
        const regulatoryFeature = new RegulatoryFeature(feature);
        console.log(regulatoryFeature.formattedDistrictName)
        
        if(inside(pt, regulatoryFeature.feature)) {
          
          const marker = L.marker([regulatoryFeature.latitude, 
              regulatoryFeature.longitude], 
              { icon: officeIcon }).addTo(map)

          marker.bindTooltip(function(layer){
              return regulatoryFeature.formattedDistrictName();},
                     {sticky: false, offset: [20, 0]});
          marker.openTooltip();
          marker.addEventListener('click', handleFeatureClick(regulatoryFeature));

          markerBounds.extend([regulatoryFeature.latitude, regulatoryFeature.longitude])
        }

      });

      markerBounds.isValid() && map.setView(markerBounds.getCenter()) && map.fitBounds(markerBounds);
      const zoom = map.getZoom();
      map.setZoom(zoom -1)
    }

    const handleFeatureClick = (regulatoryFeature) => {
      props.setFeature(regulatoryFeature.feature);
    };

    map.addControl(searchControl);
    
    map.on('geosearch/showlocation', searchEventHandler);
    
    
    return () => map.removeControl(searchControl);
  }, [props, map]);

  return null;
};

export default GeoSearch;