import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "../InfoTable.css";


// eslint-disable-next-line
const districtIcon='<svg style="width:16px;height:16px;position:relative;top:2px;" viewBox="0 0 24 24">\
    <path fill="#000000" d="M9,19V13H11L13,13H15V19H18V10.91L12,4.91L6,10.91V19H9M12,2.09L21.91,12H20V21H13V15H11V21H4V12H2.09L12,2.09Z" />\
</svg>';

// eslint-disable-next-line
const addressIcon='<svg style="width:16px;height:16px;position:relative;top:2px;" viewBox="0 0 24 24">\
<path fill="#000000" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />\
</svg>';  

// eslint-disable-next-line
const phoneIcon='<svg style="width:16px;height:16px;position:relative;top:2px;" viewBox="0 0 24 24">\
<path fill="#000000" d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />\
</svg>';

// eslint-disable-next-line
const webIcon='<svg style="width:16px;height:16px;position:relative;top:2px;" viewBox="0 0 24 24">\
<path fill="#000000" d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />\
</svg>';

const Address = (feature) =>
  [  
    '<div class="resultContainer">',
    '<div id="results">',
    '<table class="resultTable">',
    '<tr><td width=85>',
    `${districtIcon}`,
    '&nbsp&nbspDistrict:</td><td>',
    `${feature.properties.DISTRICT}`,
    `${feature.properties.DIST_ABBR}`,
    '</td></tr>',
    '<tr><td>',
    `${addressIcon}`,
    '&nbsp&nbspAddress:</td><td>',
    `${feature.properties.DISTRICT_A}`,
    '</td></tr>',
    '<tr><td>',
    `${phoneIcon}`,
    '&nbsp&nbspPhone:</td><td>',
    `${feature.properties.DISTRICT_N}`,
    '</td></tr>',     
    '<tr><td>',
    `${webIcon}`,
    '&nbsp&nbspWeb:</td><td><a href="',
    `${feature.properties.WEB_ADDR}`,
    '" target="_blank">Regulatory Web Page</a></td></tr>',
    '</table',
    '</div>',
    '</div>'
  ].join("\n");

const InfoTable = ({ feature }) => {
  const map  = useMap();

  useEffect(() => {
    const legend = L.control({ position: "topright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "results");
      div.innerHTML = Address(feature);
      return div;
    };

    if(feature) { legend.addTo(map) };

    return () => legend.remove();
  }, [map, feature]);
  return null;
};

export default InfoTable;