// import { map } from "leaflet";
import { useMap } from 'react-leaflet';
import React from "react";
import { GeoJSON } from 'react-leaflet';
import regulatoryBoundaries from "../assets/regulatory-boundaries";
import RegulatoryFeature from '../assets/regulatory-feature';


const RegulatoryData = ( { setFeature, setRegulatoryDataSelected, zoom } ) => {
    const geojson = regulatoryBoundaries;  
    const map = useMap();

    function onEachFeature(feature, layer) {
        const regulatoryFeature = new RegulatoryFeature(feature);

        if (regulatoryFeature.validRegulatoryFeature()) {
            
            layer.bindTooltip(function(layer){
                return regulatoryFeature.formattedDistrictName();
            },{sticky: false});

            layer.on({
                mouseover: highlightFeature,
                mouseout: resetHighlight, 
                click: handleFeatureClick
            });
        }
    }
    
    const handleFeatureClick = (e) => {
        const feature = e.target.feature;
        setFeature(feature);
        setRegulatoryDataSelected(true)
      };

    const highlightFeature = (e) => {
        const zoom = map.getZoom();
        if(zoom < 9 ) {
            var layer = e.target;
            layer.setStyle({
                weight: 5,
                color: "#3388FF",
                fillOpacity: 0.7
            });
            layer.bringToFront();
        }
    }
    
    const resetHighlight = (e) => {
        var layer = e.target;
        var style= {
            color:'#3388FF',
            fillOpacity: 0.2,
        }
        layer.setStyle(style);
        // layer.unbindPopup();
        //TODO need to bind again 
    }   
    
    return (
        <div>
            <GeoJSON 
                data={geojson} 
                onEachFeature={onEachFeature} 
            />
        </div>
    )
  };

  export default RegulatoryData;