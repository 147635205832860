import { Fragment } from 'react'
import { TileLayer, LayersControl } from 'react-leaflet';

const Basemaps = (props) => {
  const { basemaps } = props;
  const { BaseLayer } = LayersControl;

  const components = basemaps.map((basemap, index) => (
    <BaseLayer checked={basemap.default} name={basemap.name} key={basemap.name} >
      <TileLayer
          url={basemap.url}
          accessToken={basemap.accessToken}
          attribution={basemap.attribution}
      />
    </BaseLayer>
  ));

  return <div><Fragment>{components}</Fragment></div>
};

export default Basemaps;
