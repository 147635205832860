import L from 'leaflet';

const imageUrl  = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAYCAYAAAACqyaBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADvUlEQVRIic2WXWgcVRSAvzszO7M/zY+bTbbbNTU1VaxWE4hYf6LUB7HUHxAfqgXbir998EGrqFiDKIlSpVBoXwpWClVq1FiaPhikVEERbKUxkJjEhLVVm5DNJiSb7M/Mzlwfspl2m25iZbWeYWC4c8/57jn3nHOvxhUU7X8BP9XU5AnGrVbgKSBYcpIgDuxfdaanRYBTAK9KmBulUF7RaqN4aqMgREnZuXOj1VbszBuxVQ1Hif38YwEch9u1SA0Vz2wh/c33gCwpvHzrYyQ/+ZzswNBGoBDuIHz68jCZk6dJHf8WNVgJuo6++lrsxARIiaduJbNfHS8KKNv0CMn2Iyx7aAPZ/kGEqmL+MgiAnE3jub6e7MBQxfz8ogmnRVdg3NpI4IH7MPuHwHHw3XXbovDQrrcwfx2m6u3XSH5xDMXQGd/Zmv8rERRuZVG4nZgg+PqLCE3FaLwFHJvZzi68TQ2Yg8No0QjC78Ps6cNoaiD32+/Y4wkiH+9H+LyUb90EUrpwCciLtrIoPDcyCraNk8kgDAPr7B9Mvr+XSMdBJlp3U/b4o3jqahnbvoOafbuYfG8PE+98QPWed7GGY3MJKxfPm+J1LkFaFslD7eiNNyN0HXt6GmybUNtOhN+H0DTCB/aiVgVx0hly50YASJ34DqGqGGvXuOYE/P2wu2uwHXAkQveghauJv9zC8oP7ELoOgFoTInm4g+xP3eg33gCAXl8HilIQZJkHK0K4w0vCy5/cjNA9oChE2j8CVUF4vTjJGaRpoQYrCTy8Af+9d4NnzpxvfTMA2VPdhaEEHCld95f2PJ0BRUEJGIiAHyXgx+wbYOyFV5GpNN7mdYTaWlDCflfHnphEaEt37iVnTB04hO/OdfjuuQOzpxejqYHEm21YQzEAZj47in7daiqe3eLqzBzuQA3XoF1T646Jyw67EGBZ4Nj5MEjs0bG5up8XKUl9faIALq0c0rZRysvOj+WfRcIucduqoiC8BggF8qvW6lZCLseC1ntxSSkCIQTa1Ssu8Pwyst1YuwZyOa56aTsATiqFWlmOMz1DxfPbkKblGlMjYWQq5epWPP2E+62GqrDHE5dkFIVbQzFGNj93oXvgrnze0/OeJNuPXNKOMzVVDFEc7qRSZE/3FFX8Z1KkvSqIjB1PEHjwfuz4eKlPVHzrm5k91oUQMrkA7gh5Uv45wsynX6LVRktLBmY7u8j29eNItWsBPLPM7vRNa7vNweFt5uDwv3KNElJ8WH+2+4cF8Jt6e01gR/79T+SK3l7/AlgAZTBI33vXAAAAAElFTkSuQmCC';
   
    const OfficeIcon = L.icon({
        iconUrl: imageUrl,
        iconRetinaUrl: imageUrl,
        iconAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: [30, 30]
      });

export default OfficeIcon;